<template>
  <!--<PageHeader :title="title" :items="items" />-->
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div></div>
        <div class="col-12 mt-2">
          <div>
            <router-link to="/skip-request-listing">
              <button class="btn btn-success btn btn-success rounded-pill mb-2">
                Back
              </button>
            </router-link>
          </div>
          <div class="card-header cardHeader-bg" style="padding: 14px;">
            <h4 class="card-title mb-0">Add Sharing Request</h4>
          </div>
        </div>
        <div class="card-body">
          <div>
            <div
              v-if="alert"
              role="alert"
              aria-live="polite"
              aria-atomic="true"
              class="alert alert-danger"
            >
              {{ alertText }}
            </div>
            <b-form v-on:submit.prevent="onSubmit" @reset="onReset" v-if="show">
              <b-form-group
                id="input-group-3"
                label="Skip Id:"
                label-for="input-3"
              >
                <b-form-select
                  v-model="skipId"
                  @change="onChangeSkip()"
                  class="mb-3"
                  required
                >
                  <option value="null" disabled>Select One</option>
                  <option
                    v-for="skip in skips"
                    :value="skip.id"
                    v-bind:key="skip.id"
                    >{{ skip.id }}</option
                  >
                </b-form-select>
              </b-form-group>

              <b-form-group
                id="input-group-3"
                label="Customer:"
                label-for="input-3"
              >
                <multiselect
                  track-by="id"
                  :custom-label="customLabel"
                  label="username"
                  v-model="userId"
                  :options="users"
                  placeholder="Select customer"
                  @select="onSelectCustomer"
                  @input="onChange"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong
                      >{{ option.username }}({{ option.email }})</strong
                    ></template
                  >
                </multiselect>
              </b-form-group>

              <b-form-group
                id="input-group-3"
                label="Garbage:"
                label-for="input-3"
              >
                <b-form-select
                  @change="onChangeGarbageSpace()"
                  id="input-3"
                  v-model="garbage_space"
                  :options="sizes"
                  required
                ></b-form-select>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Zip Code:"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="zipcode"
                  type="text"
                  placeholder="Zip Code"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Estimated Price:"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="estimatedPrice"
                  type="text"
                  readonly
                  placeholder="Estimated Price"
                ></b-form-input>
              </b-form-group>

              <!-- <b-form-group
                id="input-group-2"
                label="Expiry Date:"
                label-for="input-2"
              >
                <b-form-datepicker
                  :min="new Date().toISOString().substr(0, 10)"
                  v-model="expirydate"
                  class="mb-2"
                ></b-form-datepicker>
              </b-form-group> -->

              <b-form-group
                id="input-group-2"
                label="Detail:"
                label-for="input-2"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="detail"
                  placeholder="Enter description here..."
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Images:"
                label-for="input-2"
              >
                <input
                  type="file"
                  ref="file"
                  multiple="multiple"
                  @change="submitFiles"
                />
              </b-form-group>

              <b-container fluid class="p-4 bg-dark" v-if="imagesShow">
                <b-row>
                  <b-col-4
                    v-for="(image, key) in imagesArray"
                    :key="key"
                    class="p-3"
                  >
                    <button
                      style="color:white"
                      class="close ml-1"
                      @click.prevent="removeImage(image)"
                    >
                      &times;
                    </button>
                    <b-img
                      style="height: 150px;max-width: 165px;"
                      thumbnail
                      fluid
                      :src="getLogo(image)"
                      alt="Image 1"
                    ></b-img>
                  </b-col-4>
                </b-row> </b-container
              ><br /><br />

              <b-button ref="save" type="submit" variant="primary"
                >Save</b-button
              >
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import ApiService from "@/core/services/api.service";
import axios from "axios";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      skipId: null,
      estimatedPrice: 0,
      orginalPrice: 0,
      imagesShow: false,
      userId: null,
      skipUserId: null,
      username: "",
      imagesArray: [],
      users: [],
      skips: [],
      user: null,
      detail: "",
      expirydate: "",
      alertText: "",
      alert: false,
      garbage_space: 100,
      consumedSpace: 0,
      actualSpace: 0,
      sizes: [
        "10",
        "15",
        "20",
        "25",
        "30",
        "35",
        "40",
        "45",
        "50",
        "55",
        "60",
        "65",
        "70",
        "75",
        "80",
        "85",
        "90",
        "95",
        "100",
      ],
      zipcode: "",
      show: true,
    };
  },

  mounted() {
    this.getUsers();
    this.getSkips();
  },
  methods: {
    onChangeGarbageSpace() {
      let garbagePercentageCalculation = parseFloat(
        ((this.orginalPrice / 100) * this.garbage_space).toFixed(2)
      );
      this.estimatedPrice = garbagePercentageCalculation;
    },
    onSelectCustomer(option) {
      this.username = option.username;
    },
    onChangeSkip() {
      let skipId = this.skipId;
      var valObj = this.skips.filter(function(elem) {
        if (elem.id == skipId) return elem;
      });
      if (valObj.length > 0) {
        this.skipUserId = valObj[0].userDetail.id;
        this.consumedSpace = valObj[0].consumed_space;
        this.actualSpace = valObj[0].space;
        this.estimatedPrice = (
          valObj[0].price +
          (valObj[0].price / 100) * valObj[0].skip_share_percentage
        ).toFixed(2);
        this.orginalPrice = this.estimatedPrice;
      }
    },
    customLabel({ username, email }) {
      return `${username}—(${email})`;
    },

    onChange({ zipcode }) {
      this.zipcode = zipcode;
    },
    onSubmit(evt) {
      evt.preventDefault();
      try {
        const formData = new FormData();
        if (this.userId == null) {
          evt.preventDefault();
          this.alert = true;
          this.alertText = "Customer is Required";
          return false;
        }

        if (this.skipId == null) {
          evt.preventDefault();
          this.alert = true;
          this.alertText = "Skip is Required";
          return false;
        }

        // if (this.expirydate == "") {
        //   evt.preventDefault();
        //   this.alert = true;
        //   this.alertText = "Expiry Date is required";
        //   return false;
        // }
        if (this.skipUserId == this.userId.id) {
          evt.preventDefault();
          this.alert = true;
          this.alertText = "Skip owner can not request on his own skip";
          return false;
        }

        if (this.imagesArray.length < 2) {
          evt.preventDefault();
          this.alert = true;
          this.alertText = "At Least 2 images are mandatory";
          return false;
        }

        let totlaSize = this.garbage_space + this.consumedSpace;
        let remainingSpace = this.actualSpace - this.consumedSpace + "%";

        if (totlaSize > this.actualSpace) {
          evt.preventDefault();
          this.alert = true;
          this.alertText =
            "There is only " +
            remainingSpace +
            " space available against this skip";
          return false;
        }

        this.addLoaderClasses("save");
        formData.append("user_id", this.userId.id);
        formData.append("skip_id", this.skipId);
        formData.append("garbage_space", this.garbage_space);
        formData.append("zipcode", this.zipcode);
        formData.append("username", this.username);
        formData.append("detail", this.detail);
        formData.append("images", JSON.stringify(this.imagesArray));
        // formData.append("expirydate", this.expirydate);
        formData.append("estimated_price", this.estimatedPrice);
        // const headers = {
        //   Authorization: "Bearer " + localStorage.getItem("token"),
        // };
        ApiService.post(this.$path + "/skip-request-store", formData, {
          // headers,
        })
          .then((response) => {
            if (response.data.code == 200) {
              localStorage.setItem(
                "alertmessage",
                JSON.stringify("Record has been added successfully")
              );
              this.$router.push({ name: "skip-request-listing" });
            }
            if (response.data.code == 422) {
              this.alert = true;
              this.alertText = response.data.message;
            }
          })
          .catch(({ response }) => {
            if (response) {
              alert("Technical Issue");
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    addLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },

    getLogo(logo) {
      return this.$IMAGE_BASE_URL + logo;
    },

    removeImage(name) {
      this.imagesArray.splice(
        this.imagesArray.findIndex((x) => x === name),
        1
      );
      if (this.imagesArray.length == 0) {
        this.imagesShow = false;
      }
    },

    submitFiles() {
      Swal.fire({
        title: "Processing...",
        text: "Please wait while your request is being processed",
        buttons: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        closeOnClickOutside: false,
        allowEscapeKey: false,
      });

      let formData = new FormData();

      for (var i = 0; i < this.$refs.file.files.length; i++) {
        let file = this.$refs.file.files[i];
        formData.append("images[" + i + "]", file);
      }

      ApiService.post(this.$path + "/save-multiple-image", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          if (response.data.code == 200) {
            response.data.data.map((x) => {
              this.imagesArray.push(x);
            });
            this.imagesShow = true;
            Swal.close();
          }
        })
        .catch(function(response) {
          if (response) {
            alert("Technical Issue");
          }
        });
    },

    getUsers() {
      try {
        axios.get(this.$path + "/dropdown-user-list").then((response) => {
          this.users = response.data.data;
        });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    getSkips() {
      try {
        axios.get(this.$path + "/skip-share-list-admin").then((response) => {
          this.skips = response.data.data;
        });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
<style scoped>
#add-loan-btn {
  padding: 8px 25px 8px 25px;
}
.multiselect * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 13px;
}
</style>
